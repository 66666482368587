<template>
  <custom-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                Estratificación: {{ item.title }}
              </div>
            </template>
            <v-card-text>
              <template v-if="item && sections && sections.length > 0">
                <v-list two-line>
                  <draggable
                    v-model="sections"
                    handle=".handle"
                    class="list-group"
                    v-bind="dragOptions"
                    @update="update"
                    draggable=".draggable"
                  >
                    <transition-group type="transition" name="flip-list">
                      <v-list-item
                        v-for="section in sections"
                        :key="section.id"
                        :to="{
                          name: 'stat_sections_show',
                          params: { id: section.id, parent: item },
                        }"
                        class="chapter-item draggable elevation-1"
                      >
                        <v-list-item-avatar>
                          <v-icon> mdi-chart-box-outline </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-uppercase"
                            v-text="section.title"
                          ></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-avatar class="handle">
                          <v-icon> mdi-reorder-horizontal </v-icon>
                        </v-list-item-avatar>
                      </v-list-item>
                    </transition-group>
                  </draggable>
                </v-list>
              </template>
              <template v-else>
                <div class="text-center">
                  <h3 class="display-1 font-weight-light text--primary my-5">
                    No se han creado variables para esta estratificación.
                  </h3>
                  <va-action-button
                    :to="{ name: 'stat_sections_create', params: { parent: item } }"
                    icon="mdi-chart-box-plus-outline"
                    label="Crear Variable"
                  />
                </div>
              </template>
            </v-card-text>
          </base-material-card>
        </v-col>
        <v-col cols="3">
          <v-card class="mt-8">
            <v-list color="transparent" subheader v-if="item">
              <v-subheader>Resultados</v-subheader>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="info"> mdi-marker-check </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Puntuación máxima: {{ item.max_score }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="red"> mdi-network-strength-4-alert </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Nivel 1: {{ item.result_level_1.max }} - {{ item.result_level_1.min }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="warning" > mdi-network-strength-2-alert </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Nivel 2: {{ item.result_level_2.max }} - {{ item.result_level_2.min }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="success"> mdi-network-strength-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Nivel 3: {{ item.result_level_3.max }} - {{ item.result_level_3.min }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-subheader>Detalles</v-subheader>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-star </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Creado {{ $helpers.dateFromNow(item.created_at) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-pen </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Modificado {{ $helpers.dateFromNow(item.updated_at) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list color="transparent" subheader>
              <v-subheader>Acciones</v-subheader>
              <v-list-item
                :to="{ name: 'stat_sections_create', params: { parent: item } }"
              >
                <v-list-item-icon>
                  <v-icon> mdi-chart-box-plus-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Crear variable</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </va-show>
  </custom-show-layout>
</template>

<script>
import draggable from "vuedraggable";

export default {
  props: ["item"],
  components: {
    draggable
  },
  data() {
    return {
      sections: this.item.sections
    };
  },
  methods: {
    async update() {
        this.sections.map((el, index) => {
            el.priority = index + 1;
            this.$store.dispatch("stat_sections/update", { id: el.id, data: { priority: el.priority }});
        })
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        disabled: false,
        ghostClass: "ghost",
        forceFallback: true,
        fallbackClass: "clone_card",
      };
    }
  }
}
</script>